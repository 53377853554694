<template>
  <div class="dashboard-container" v-loading="loading">
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>关键词排行</p>
            <p>
              <span :class="active==='week'?'active':'no-active'" @click="handleTime(7)">近7天</span>
              <span :class="active==='mouth'?'active':'no-active'" @click="handleTime(30)">近30天</span>
              <span :class="active==='year'?'active':'no-active'" @click="handleTime(365)">近1年</span>
              <el-date-picker
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                style="width: 250px;margin-left: 10px;"
                v-model="advancedForm.times"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="{disabledDate: MixinDisabledDate}"
                @change="handleTimeChange"
              ></el-date-picker>
            </p>
          </div>
          <div class="echart-alltitle">
            <div :span="24" id="echarts_right" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>所有搜索内容排行</p>
          </div>
          <div class="echart-alltitle">
            <en-table-layout :toolbar="false" :tableData="tableData.data" :loading="loading">
              <!-- 表格数据 -->
              <template slot="table-columns">
                <el-table-column type="index" label="排行" />
                <el-table-column prop="keyword_name" label="关键词" />
                <el-table-column prop="search_num" label="总搜索"/>
              </template>
              <!-- 表格分页 -->
              <el-pagination
                v-if="tableData"
                slot="pagination"
                @size-change="handlePageSizeChange"
                @current-change="handlePageCurrentChange"
                :current-page="tableData.page_no"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="tableData.page_size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.data_total"
              ></el-pagination>
            </en-table-layout>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_Common from "@/api/common";
import * as API_HotKeyword from "@/api/hotKeyword";

export default {
  name: "dashboard",
  data() {
    return {
      loading: true,
      goodsList: null,
      memberList: null,
      /** 基本数据 */
      info: {},
      advancedForm: {
        times: "",
      },
      // 关键词排行
      active: "day",
      activeName: "近7天",
      tableData: [],
      page_no: 1,
      page_size: 10,
    };
  },
  filters: {
    secrecyMobile(mobile) {
      mobile = String(mobile);
      if (!/\d{11}/.test(mobile)) {
        return mobile;
      }
      return mobile.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    },
  },
  activated() {},
  mounted() {
    this.$nextTick(() => {
      this.sesalChart = this.$echarts.init(
        document.getElementById("echarts_right")
      );
    });
    this.handleTime(7);
    this.GET_List();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.page_no = page;
      this.GET_List();
    },
    handleTimeChange(v) {
      this.active = 1;
      this.Get_Chart();
    },
    handleTime(has) {
      const end = new Date();
      let start;
      if (has === 365) {
        // 近1年
        start = new Date().setHours(0, 0, 0, 0) - 364 * 24 * 60 * 60 * 1000;
        this.active = "year";
        this.activeName = "近1年";
      } else if (has === 7) {
        // 近七天
        start = new Date().setHours(0, 0, 0, 0) - 6 * 24 * 60 * 60 * 1000;
        this.active = "week";
        this.activeName = "近7天";
      } else if (has === 30) {
        // 近30天
        start = new Date().setHours(0, 0, 0, 0) - 29 * 24 * 60 * 60 * 1000;
        this.active = "mouth";
        this.activeName = "近30天";
      }
      this.advancedForm.times = [start, end];
      this.Get_Chart();
      this.loading = false;
    },
    Get_Chart() {
      const start = parseInt(this.advancedForm.times[0] / 1000);
      const end = parseInt(this.advancedForm.times[1] / 1000);
      API_HotKeyword.getHotwordsimplechart({
        start,
        end
      }).then((res) => {
        this.loading = false;
        const x = res.xAxis;
        const s = res.series;
        const option = {
          xAxis: {
            type: 'category',
            data: x
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: s.data,
            type: 'bar'
          }]
        };
        this.sesalChart.setOption(option);
      });
    },
    GET_List() {
      const start = parseInt(this.advancedForm.times[0] / 1000);
      const end = parseInt(this.advancedForm.times[1] / 1000);
      API_HotKeyword.getHotwordpage({
        // start,
        // end,
        page_no: this.page_no,
        page_size: this.page_size
      }).then((res) => {
        this.loading = false;
        this.tableData = res;
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;
  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    margin-top: 22px;
  }
  div {
    span {
      display: block;
    }
    span:nth-child(1) {
      color: #212121;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #434343;
    }
  }
  img {
    width: 82px;
    height: 82px;
  }
}
.echart-title::after {
  display: none;
}
.echart-title {
  height: 50px;
  font-size: 18px;
  color: #007f7f;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }
  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}

.echart-alltitle {
  display: flex;
  justify-content: space-around;
}
.echarts-left1 {
  width: 15%;
}
.echarts-left {
  height: 100px;
  width: 100%;
  background: rgba(51, 205, 189, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
    color: #212121;
  }
  p {
    font-size: 15px;
    line-height: 0px;
    font-weight: 500;
    margin-top: -10px;
    color: #656565;
  }
}
.echarts-left:nth-child(2),
.echarts-left:nth-child(3) {
  background: rgba(253, 85, 87, 0.08);
}
.echarts-right {
  width: 80%;
  height: 350px;
}

.elcard {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #f3f5f7;
    border-radius: 8px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.dashboard-container {
  height: 100%;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .goods-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      display: block;
      margin: 0;
      width: 75%;
      button {
        width: 100%;
      }
    }
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
.el-row {
  position: relative;
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
</style>
